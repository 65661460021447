<template>
  <Teleport to="body">
    <div
      class="modalBg modalHeight w-full flex items-center justify-center mobOnly:px-12"
      @click.self="background"
    >
      <div
        class="bg-lightest w-full max-w-556 p-16 desk:p-32 max-h-[80vh] overflow-y-auto shadow-xl"
        :class="{'max-w-desktopMd': isSideBySideComparison}"
      >
        <div v-if="message" class="type-headline-base mb-16">
          {{ message }}
        </div>
        <slot />
        <div class="flex flex-row items-center justify-end gap-32">
          <button
            v-if="showAbort"
            class="btn btn--text"
            @click="abort"
          >
            {{ abortText ? abortText : $t('modal.generic.abort') }}
          </button>
          <button
            v-if="showConfirm"
            class="btn"
            @click="confirm"
          >
            {{ confirmText ? confirmText : $t('modal.generic.ok') }}
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const { $t } = useNuxtApp();
const props = withDefaults(defineProps<{
  message?: string,
  showAbort?: boolean,
  showConfirm?: boolean,
  abortText?: string,
  confirmText?: string,
  isSideBySideComparison?: boolean,
}>(), {
  message: '',
  showAbort: () => false,
  showConfirm: () => true,
  isSideBySideComparison: () => false,
  abortText: '',
  confirmText: '',
});

const emit = defineEmits<{
  (e: 'abort'): void;
  (e: 'confirm'): void;
  (e: 'close'): void;
  (e: 'clickOutside'): void;
}>();

const background = () => {
  if (!props.showAbort) {
    confirm();
  }
  emit('clickOutside');

};

const confirm = () => {
  emit('confirm');
  emit('close');
};

const abort = () => {
  emit('abort');
  emit('close');
};

</script>

<style scoped lang="postcss">
</style>
